$(document).foundation();

// Search panel slide
$("#search-flip").click(function(){
    $(".search-panel").slideToggle("slow");
});

// Search panel slide - mob
$("#mobile-search-flip").click(function(){
    $(".search-panel").slideToggle("slow");
});


// Toggle search button icon
$(".search-bttn").click(function() {
	if ($(this).attr("class") === "search-bttn") {
	  this.src = this.src.replace("icon-search","icon-search-close");
	} else {
	  this.src = this.src.replace("icon-search-close","icon-search");
	}
	$(this).toggleClass("on");
});

// Toggle button text
$(".toggle-button").click(function() {
	$(".toggle-button .btn-text").html($(".toggle-button .btn-text").html() === 'Read More' ? 'Read Less' : 'Read More');
	// Change FA icon
    if ($(this).find('svg').attr('data-icon') === 'angle-down' ) {
        $(this).find('svg').attr('data-icon', 'angle-up');
      } else {
        $(this).find('svg').attr('data-icon', 'angle-down');
      }
});